<template>
  <div>
    <router-view v-show="$route.matched.length>=3"></router-view>
    <PageHeaderLayout v-show="$route.matched.length==2">
      <div class="main-page-content">
        <el-row class="table-header" type="flex" justify="space-between">
          <el-col>
            <div style="display: flex;justify-content: space-between">
              <el-button type="primary" size="medium" v-if="userPermissions.indexOf('special_topic_create') != -1 "
                         @click="addButton(0)">添加
              </el-button>
              <el-switch
                  v-model="isSort"
                  inactive-text="列表排序">
              </el-switch>
            </div>
          </el-col>
          <!--                    <el-col :span="5" :offset="1">-->
          <!--                        <change-question-bank></change-question-bank>-->
          <!--                    </el-col>-->
        </el-row>

        <el-row class="table-search">
          <el-form :inline="true" :model="searchForm" class="demo-form-inline">
            <el-form-item label="标题">
              <el-input placeholder="请输入标题名称" clearable v-model="searchForm.title"/>
            </el-form-item>
            <el-form-item>
              <el-button type="primary" @click="onSearchClick" style="margin-left: 12px">搜索</el-button>
            </el-form-item>
            <el-form-item>
              <el-button @click="onResetSearch">重置</el-button>
            </el-form-item>
          </el-form>
        </el-row>

        <ApeTable ref="apeTable" :data="questionBankList" :columns="columns" :loading="loadingStatus"
                  :pagingData="pagingData" @switchPaging="switchPaging" highlight-current-row>
<!--          <el-table-column-->
<!--              slot="first-column"-->
<!--              width="80"-->
<!--              align="center"-->
<!--              label="Drag">-->
<!--            <template slot-scope="scope">-->
<!--              <el-tooltip effect="dark" content="拖动排序" placement="top-start">-->
<!--                <span class="drag-handle" :data-id="scope.row.id"><i class="el-icon-rank"></i></span>-->
<!--              </el-tooltip>-->
<!--            </template>-->
<!--          </el-table-column>-->
          <el-table-column
              v-if="buttonType==='icon'"
              label="操作"
              fixed="right"
              width="200">
            <template slot-scope="scope">
              <span v-if="!isSort">
                     <el-tooltip effect="dark" content="分类" placement="top-start"
                                 v-if="userPermissions.indexOf('special_topic_add') !== -1">
                  <el-button size="mini" type="primary" icon="el-icon-tickets"
                             @click="addKindButton(scope.row)"></el-button>
                </el-tooltip>
                <el-tooltip effect="dark" content="编辑" placement="top-start"
                            v-if="userPermissions.indexOf('special_topic_edit') !== -1">
                  <el-button size="mini" icon="el-icon-edit" @click="editButton(scope.row.id)"></el-button>
                </el-tooltip>

                  <el-tooltip effect="dark" content="删除" placement="top-start"
                              v-if="userPermissions.indexOf('special_topic_delete') !== -1">
                  <el-button size="mini"
                             type="danger"
                             icon="el-icon-delete"
                             @click="deleteButton(scope.row.id)"></el-button>
                </el-tooltip>
              </span>

              <span v-if="isSort">
                <el-tooltip effect="dark" content="置顶" placement="top-start">
                    <el-button size="mini" icon="el-icon-upload2" @click="onStickClick(scope.row.id)"></el-button>
                </el-tooltip>
                <el-tooltip effect="dark" content="置底" placement="top-start">
                    <el-button size="mini" icon="el-icon-download" @click="onUndersideClick(scope.row.id)"></el-button>
                </el-tooltip>
                <el-tooltip effect="dark" content="向上排序" placement="top-start">
                    <el-button size="mini" icon="el-icon-top" @click="onUpwardClick(scope.row.id)"></el-button>
                </el-tooltip>
                <el-tooltip effect="dark" content="向下排序" placement="top-start">
                    <el-button size="mini" icon="el-icon-bottom" @click="onDownwardClick(scope.row.id)"></el-button>
                </el-tooltip>
              </span>
            </template>
          </el-table-column>
          <el-table-column
              v-if="buttonType==='text'"
              label="操作"
              width="200"
              fixed="right">
            <template slot-scope="scope">
              <div>
                <el-button size="mini"
                           type="primary"
                           @click="addKindButton(scope.row)"
                           v-if="userPermissions.indexOf('special_topic_add') !== -1"
                >分类
                </el-button>

                <el-button size="mini" v-if="userPermissions.indexOf('special_topic_edit') !== -1"
                           @click="editButton(scope.row.id)">编辑
                </el-button>

                <el-button type="danger"
                           size="mini"
                           v-if="userPermissions.indexOf('special_topic_delete') !== -1"
                           @click="deleteButton(scope.row.id)">删除
                </el-button>
              </div>
            </template>
          </el-table-column>
        </ApeTable>
      </div>
    </PageHeaderLayout>
    <ApeDrawer :drawerData="drawerData" @drawerClose="drawerClose" @drawerConfirm="drawerConfirm">
      <template slot="ape-drawer">
        <el-col :span="18" class="content-left">
          <el-form ref="specialTopicForm" :model="formData" :rules="rules" label-width="80px">
            <el-form-item label="标题" prop="title">
              <el-input v-model="formData.title" placeholder="请输入名称"></el-input>
            </el-form-item>
            <el-form-item label="序号" prop="order">
              <el-input v-model="formData.order" maxlength="5" onkeyup="value=value.replace(/\D/g,'')"
                        @input="isChangeOrder" :disabled="orderDisabled" :placeholder="orderPlaceholder"></el-input>
              <div style="color: #999999;font-size: 12px">(数值越低排序越靠前)</div>
            </el-form-item>
            <el-form-item label="缩略图" prop="thumb_img">
              <ApeUploader :limit="1" @handleUploadSuccess="handleThumbUploadSuccess"
                           @handleUploadRemove="handleThumbUploadRemove"
                           @handleUploadError="handleUploadError"
                           :upload-file-list="uploadThumbFileList"></ApeUploader>
            </el-form-item>
            <el-form-item label="封面" prop="cover_images">
              <ApeUploader :limit="3" @handleUploadSuccess="handleUploadSuccess"
                           @handleUploadRemove="handleUploadRemove"
                           @handleUploadError="handleUploadError"
                           :upload-file-list="uploadFileList"></ApeUploader>
            </el-form-item>
          </el-form>
        </el-col>
      </template>
    </ApeDrawer>
  </div>
</template>

<script>
const DEL = "del";
const NO_DEL = "no_del";
const IS_SUCCESS = "ok";

import PageHeaderLayout from '@/layouts/PageHeaderLayout';
import ApeTable from '@/components/ApeTable';
import ApeDrawer from '@/components/ApeDrawer';
import ApeUploader from '@/components/ApeUploader';
import {mapGetters} from 'vuex';
import Sortable from 'sortablejs'

export default {
  components: {
    PageHeaderLayout,
    ApeTable,
    ApeDrawer,
    ApeUploader,
  },
  data() {
    return {
      orderDisabled: false,
      isSort: false,
      loadingStatus: false,
      columns: [
        {
          title: '序号',
          value: 'order',
          width: '80'
        },
        {
          title: '标题',
          value: 'title',
          width: '300'
        },
        {
          title: '缩略图',
          type: 'image',
          value: 'thumb_pic_url'
        },
        {
          title: '封面',
          type: 'image',
          value: 'cover_pic_url'
        },
        // {
        //     title: '信息',
        //     value: [
        //         {label: "头部图(个):", value: "banner_count"},
        //         {label: "分类(个):", value: "category_count"},
        //         {label: "文章(篇):", value: "resource_count"}
        //     ],
        // },
        {
          title: '头部图(个)',
          value: 'banner_count',
          width: 120,
        },
        {
          title: '分类(个)',
          value: 'category_count',
          width: 120,
        },
        {
          title: '文章(篇)',
          value: 'resource_count',
          width: 120,
        },
        {
          title: '创建时间',
          value: 'created_at',
          width: 200,
        },
      ],
      // 表格列表数据
      questionBankList: [],
      // 分页信息
      pagingData: {
        is_show: true,
        layout: 'total, sizes, prev, pager, next, jumper',
        total: 0,
        // offset: 0, // 分页的offset,序号列使用
      },
      // 抽屉数据
      drawerData: {
        visible: false,
        loading: true,
        loading_text: '玩命加载中……',
        title: '',
        width_height: '560px',
      },
      //抽屉表单
      formData: {
        title: "",
        image: "",
        cover_images: [],
        thumb_img:"",
        order:""
      },

      //ImageIds
      coverImages: [],

      // 表单验证
      rules: {
        title: [
          {required: true, message: '输入标题', trigger: 'blur'},
        ],
        thumb_img: [
          {required: true, message: '上传图片', trigger: 'blur', validator: this.apeUploaderVerify},
        ],
        cover_images: [
          {required: true, message: '上传图片', trigger: 'blur', validator: this.apeUploaderVerify},
        ],
        order: [{required: true, message: '请输入序号', trigger: 'blur'}],
      },
      //题型列表
      typeList: [
        {
          id: "all",
          display_name: "全部题型"
        }, {
          id: "single_choice",
          display_name: "单选题"
        },
        {
          id: "multiple_choice",
          display_name: "不定项选择题"
        },
        {
          id: "case_analysis",
          display_name: "案例分析题"
        },
        {
          id: "essay_questions",
          display_name: "论述题"
        },
      ],

      // 已上传图片列表
      uploadFileList: [],
      //缩略图
      uploadThumbFileList: [],
      stId: "",
      searchForm: {},
      searchCondition: {}
    }
  },
  watch: {
    'curChooseBankId'(newVal) {
      if (newVal) {
        this.getSpecialTopicList(DEL);
      }
    },
    pagingData: {
      handler(data) {
        if(data.total <= 0) {
          this.orderDisabled = true
          this.$set(this.formData, 'order', 0)
        } else {
          this.orderDisabled = false
        }
      }
    }

  },

  mounted() {
    this.getSpecialTopicList(DEL);
    this.$nextTick(() => {
      this.dragSort()
    });
  },

  methods: {
    // 切换页码操作
    async switchPaging() {
      this.getSpecialTopicList(NO_DEL)
    },

    // 响应添加按钮
    async addButton() {
      this.resetFormData();
      this.drawerData.loading_text = '玩命加载中……';
      this.drawerData.visible = true;
      this.drawerData.title = '添加专题';
      this.uploadFileList = []
      this.uploadThumbFileList = []

      this.$nextTick(() => {
        this.orderDisabled = true
        this.$set(this.formData, 'order', 0)
        this.drawerData.loading = false
      });
    },

    // 响应编辑按钮
    async editButton(id) {
      this.drawerData.loading_text = '玩命加载中……';
      this.drawerData.visible = true;
      this.drawerData.title = '修改专题';
      let {info} = await this.$api.getSpecialTopicInfo({id});
      this.$nextTick(() => {
        this.drawerData.loading = false;

        this.uploadFileList = [];
        this.uploadThumbFileList = [];
        this.coverImages = [];

        for (let image of info.cover_images_info) {
          let imageObject = {
            id: image.id,
            name: image.title,
            url: image.full_path
          };
          this.uploadFileList.push(imageObject);
          this.coverImages.push(image.id);
        }

        if (info.thumb_info) {
          this.uploadThumbFileList = [{
            id: info.thumb_info.id,
            name: info.thumb_info.title,
            url: info.thumb_info.full_path
          }]
        }

        // this.formData = {}
        this.formData.id = info.id
        this.formData.title = info.title
        this.formData.order = info.order
        this.formData.thumb_img = ""
        if (info.thumb_info) {
          this.formData.thumb_img = info.thumb_info.id
        }

        let coverImages = []
        for (let image of info.cover_images_info) {
          coverImages.push(image.id)
        }
        this.formData.cover_images = coverImages
      });
    },

    //响应分类
    addKindButton(row) {
      this.$router.push(this.$route.path + '/' + row.id + '/category');
    },

    // 处理抽屉关闭
    drawerClose() {
      this.resetFormData();
      this.drawerData.visible = false;
      this.drawerData.loading = true;
      this.uploadFileList = [];
      this.uploadThumbFileList = [];
    },

    // 处理抽屉确认
    async drawerConfirm() {
      // 调用组件的数据验证方法
      this.$refs['specialTopicForm'].validate((valid) => {
        if (valid) {
          this.formSubmit()
        } else {
          this.$message.error('数据验证失败，请检查必填项数据！')
        }
      });

      this.uploadFileList = [];
      this.uploadThumbFileList = [];
    },

    // form数据提交，请求接口
    async formSubmit() {
      this.drawerData.loading_text = '玩命提交中……';
      // this.drawerData.loading = true;
      let params = {}
      if (this.formData.id) {
        params.id = this.formData.id
      }
      params.title = this.formData.title
      params.thumb_img = this.formData.thumb_img
      params.cover_images = this.formData.cover_images
      if (this.formData.order !== "" && this.formData.order !== undefined) {
        params.order = parseInt(this.formData.order)
      }
      console.log(params)
      // 调用接口
      let id = await this.$api.storeSpecialTopic(params);
      this.$nextTick(() => {
          this.drawerData.visible = false
      });
      this.$nextTick(() => {
          if (id) {
              this.getSpecialTopicList(DEL)
          }
      });
      this.$nextTick(() => {
          this.$message.success('保存成功!')
      });
      this.resetFormData()
    },

    // 相应删除按钮
    deleteButton(id) {
      this.$confirm('是否删除该专题?', '提示', {
        confirmButtonText: '确定',
        cancelButtonText: '取消',
        type: 'warning'
      }).then(async () => {
        let info = await this.$api.deleteSpecialTopic({id});
        if (info === IS_SUCCESS) {
          this.$nextTick(() => {
            this.getSpecialTopicList(DEL);
            this.$message.success("删除成功");
          })
        } else {
          this.$message.error("删除失败");
        }
      })
    },

    // 初始化数据
    resetFormData() {
      this.coverImages = []
      // 初始化form表单
      this.formData = {
        title: "",
        image: "",
        cover_images: [],
        thumb_img:"",
        order: ""
      };
      this.orderDisabled = false
      this.$nextTick(() => {
        this.$refs['specialTopicForm'].resetFields();
      })
    },

    async getSpecialTopicList(type, params = {}) {
      this.loadingStatus = true;
      let pagingInfo = this.$refs['apeTable'].getPagingInfo(type);
      let data = {};
      data = pagingInfo;
      for (let key in params) {
        data[key] = params[key];
      }
      if (type === DEL) {
        data.current_page = 1
        this.$refs['apeTable'].resetCurPageSize();
      }
      let {list, pages} = await this.$api.getSpecialTopicList(data);
      this.questionBankList = [];
      this.$nextTick(() => {
        this.questionBankList = list
      });
      this.pagingData.total = pages.total;
      // this.pagingData.offset = pages.offset;
      this.loadingStatus = false
    },

    // 拖拽排序数据提交，请求接口
    async dragSortSubmit(orderIds) {
      let data = {
        ids: orderIds
      };
      let info = await this.$api.orderSpecialTopic(data);
      if (info == 'ok') {
        this.$nextTick(() => {
          this.getSpecialTopicList(DEL)
        })
      }
      this.$message.success('保存成功!')
    },

    // 表格拖拽排序，同层级移动有效果
    dragSort() {
      const el = document.querySelectorAll('.el-table__body-wrapper > table > tbody')[0];
      this.sortable = Sortable.create(el, {
        handle: ".drag-handle",
        setData: function (dataTransfer) {
          dataTransfer.setData('Text', '')
        },
        onEnd: () => {
          let Ids = [];
          let tmp = el.querySelectorAll('.drag-handle');
          for (let i = 0, len = tmp.length; i < len; i++) {
            Ids.push(tmp[i].dataset.id)
          }
          this.dragSortSubmit(Ids)
        },
      })
    },

    // 图片上传成功回调
    handleUploadSuccess(file, fileList) {
      this.coverImages.push(file.id);
      this.formData.cover_images = this.coverImages;

      this.uploadFileList = fileList
    },

    // 图片删除回调
    handleUploadRemove(file, fileList) {
      this.$utils.removeKeyFormArray(this.coverImages, file.id);
      this.formData.cover_images = this.coverImages;

      this.uploadFileList = fileList
    },

    //缩略图
    handleThumbUploadSuccess(file, fileList) {
      this.formData.thumb_img = file.id;
      this.uploadThumbFileList = fileList
    },

    // 缩略图删除回调
    handleThumbUploadRemove(file, fileList) {
      this.formData.thumb_img = 0;
      this.uploadThumbFileList = fileList
    },

    //图片上传失败
    handleUploadError() {
      this.$message("图片上传失败");
    },

    // 图片上传自定义验证
    apeUploaderVerify(rule, value, callback) {
      if (rule.required && !this.formData.thumb_img && !this.formData.cover_images.length > 0) {
        callback(new Error(rule.message))
      }
      callback()
    },

    //搜索
    onSearchClick() {
      this.$refs['apeTable'].resetCurPageSize();
      this.getSpecialTopicList(DEL, this.searchForm);
    },

    //搜索重置
    onResetSearch() {
      this.searchForm = {};
      this.getSpecialTopicList(DEL);
    },
    isChangeOrder(e) {
      let val = parseInt(e)
      if(!val) {
        return
      }
      this.$set(this.formData, "order", val)
      let newVal = ""
      if (val <= 0) {
        newVal = 0
        this.$set(this.formData, "order", newVal)
      } else if (val >= this.pagingData.total) {
        newVal = (this.pagingData.total - 1)
        this.$set(this.formData, "order", newVal)
      }
    },
    //置顶
    async onStickClick(id) {
      let data = {
        id : id,
        type: 'top'
      }
      await this.$api.orderSpecialTopic(data)
      await this.getSpecialTopicList(DEL)
    },
    //置底
    async onUndersideClick(id) {
      let data = {
        id : id,
        type: 'bottom'
      }
      await this.$api.orderSpecialTopic(data)
      await this.getSpecialTopicList(NO_DEL)
    },
    //向上排序
    async onUpwardClick(id) {
      let data = {
        id : id,
        type: 'up'
      }
      await this.$api.orderSpecialTopic(data)
      await this.getSpecialTopicList(NO_DEL)
    },
    //向下排序
    async onDownwardClick(id) {
      let data = {
        id : id,
        type: 'down'
      }
      await this.$api.orderSpecialTopic(data)
      await this.getSpecialTopicList(NO_DEL)
    },
  },
  computed: {
    ...mapGetters(['userPermissions', 'buttonType', 'curChooseBankId']),
    orderPlaceholder(){
      let placeholder = ""
      if ((this.pagingData.total-1) < 0) {
        return placeholder
      }
      placeholder = '请输入0-' + (this.pagingData.total-1) + '之间的数字'
      return placeholder
    }
  }
}
</script>

<style lang="stylus" scoped>
.table-header
  margin-bottom 12px

.drag-handle
  font-size 24px
  cursor pointer

.el-input-group__prepend, .el-input-group__append
  background #ffffff
  padding 0 12px

.el-input-group__append
  color #ffffff
  background #1890ff

.el-popover .el-checkbox-group .el-checkbox
  margin-left 0px
  margin-right 12px

.el-radio.is-bordered
  width 100px

.el-color-picker
  position absolute

/deep/ .el-switch__label
  color #0099ff
</style>
<style>
.el-table--scrollable-x .el-table__body-wrapper {
  z-index: 1;
}

.el-table .el-table--scrollable-x .el-table__body-wrapper {
  padding: 0 0 5px 0;
  margin: 0 0 5px 0;
  overflow-x: auto;
}
</style>